import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/seo';
import CompliancePageWrapper from '../components/compliance-page-wrapper';
import Inner from '../components/Inner';
import SecurityPrivacyTitle from '../components/SecurityPrivacyTitle';
import * as Styles from '../styles/pages/_privacy.module.scss';
import BackButton from '../components/back-button';

export default function About() {
  const breadcrumb = [{ label: 'プライバシーポリシー', url: '/privacy' }];

  return (
    <>
      <Seo
        url="/privacy"
        title="プライバシーポリシー｜Uniforce（ユニフォース）株式会社"
        description="Uniforce株式会社での個人情報の扱いやサービスの規約などのポリシーを掲載しています。"
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={breadcrumb}
      />
      <Layout breadcrumb={breadcrumb}>
        <CompliancePageWrapper>
          <Inner>
            <SecurityPrivacyTitle en="Policy" jp="プライバシーポリシー" />
            <div className={Styles.privacy}>
              <div className={Styles.privacy__text}>
                <p className={Styles.privacy__top_description}>
                  Uniforce株式会社（以下「当社」といいます。）は、この「プライバシーポリシー」に基づき、お客様の個人情報を取扱います。
                </p>

                <h2 className={Styles.privacy__subtitle}>第１条（個人情報）</h2>
                <p>
                  「個人情報」とは、個人情報の保護に関する法律（平成十五年法律第五十七号、以下「個人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。
                </p>

                <h2 className={Styles.privacy__subtitle}>
                  第２条（個人情報の取得と利用）
                </h2>
                <p>
                  当社は、以下の目的に必要な範囲で、お客様の個⼈情報を取得し、取得した情報を利用させていただきます。以下の⽬的の範囲を超えて個⼈情報を利⽤する場合には、事前に適切な⽅法でお客様からの同意を得るものとします。
                </p>
                <ol className={Styles.privacy__number_list}>
                  <li>
                    当社のサービス（以下「本サービス」といいます。）を提供するため
                  </li>
                  <li>
                    当社ウェブサイト及び本サービスの内容を改良・改善し、又は新サービスを開発するため
                  </li>
                  <li>
                    当社ウェブサイト及び本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内のため
                  </li>
                  <li>
                    メンテナンス、重要なお知らせなど必要に応じたご連絡のため
                  </li>
                  <li>
                    お客様からのお問い合わせに回答するため（本人確認を行うことを含む）
                  </li>
                  <li>
                    当社ウェブサイト及び本サービスの利用状況をお客様にご報告するため
                  </li>
                  <li>
                    当社ウェブサイト及び本サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため
                  </li>
                  <li>
                    当社ウェブサイト及び本サービスの利用履歴等を調査・分析し、その結果を当社ウェブサイト及び本サービスを含む当社のサービス、製品の改良・開発や広告の配信に利用するため
                  </li>
                </ol>
                <p className="pc-only">
                  （※統計情報等、個人を特定できない形で、分析、マーケティングその他弊社の事業目的に使用し又は第三者に提供することがあります。）
                </p>

                <h2 className={Styles.privacy__subtitle}>
                  第３条（個人情報の管理と保護）
                </h2>
                <p>
                  個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、お客様の同意がない限り、第三者に対しデータを開示・提供することはいたしません。
                  <br />
                  また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。
                </p>
                <ul>
                  <li>法令に基づく場合</li>
                  <li>
                    人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難である場合
                  </li>
                  <li>
                    公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難である場合
                  </li>
                  <li>
                    国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                  </li>
                  <li>
                    業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
                  </li>
                  <li>
                    合併その他の事由による事業の承継に伴って個人情報が提供される場合
                  </li>
                </ul>

                <h2 className={Styles.privacy__subtitle}>
                  第４条（個人情報の取扱いの委託）
                </h2>
                <p>
                  当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。
                </p>

                <h2 className={Styles.privacy__subtitle}>
                  第５条（クッキー等の利用）
                </h2>
                <ol>
                  <li>
                    弊社はお客様情報の分析にクッキー（cookie）、ウェブビーコン、SDK、収集タグなどの端末識別子（以下、「クッキー等」といいます）を使用しています。
                  </li>
                  <li>
                    クッキー等は、お客様の本サービス及び当社ウェブサイト等の利用状況等を、お客様のコンピュータ、スマートフォン等のインターネット接続可能なデバイス（ウェブブラウザ）内に記憶させておく機能です。当社は、クッキー等を利用することにより、お客様による本サービス、当社ウェブサイト等へのアクセス情報、利用状況、閲覧情報などを受け取っています。
                  </li>
                  <li>
                    お客様は、各々のブラウザの設定においてクッキー等を無効にすることができますが、その場合、本サービス又は当社ウェブサイト等の利便性が損なわれたり、本サービス又は当社ウェブサイト等で提供するサービスのご利用範囲が限定されたりすることがあります。
                  </li>

                  <li>
                    当社は、クッキー等を通じて受け取る情報を、お客様が本サービス又は当社ウェブサイト等における各種サービスをより便利で円滑にご利用いただくために使用しています。また、お客様の興味やニーズに適したサービスを提供するため、本サービス又は当社ウェブサイトで提供するサービスの利用状況等の把握や分析に使用する場合があります。当社は、その分析結果を、本サービスの運営、品質維持、改善の目的のほか、当社の商品やサービスの開発、改善等、およびお客様のサービス利用状況に合わせた広告配信等に活用させていただきます。なお、クッキー等を通じて受け取った情報を統計化した情報（個人を特定しえない情報）については、上記目的のほか、当社の事業目的に使用し、または第三者に提供することがあります。
                  </li>
                </ol>

                <h2 className={Styles.privacy__subtitle}>
                  第６条（個人情報の取得と利用）
                </h2>
                <p>
                  当社は、お客様から個人情報の開示を求められたときは、お客様に対し、遅滞なくこれを開示します。但し、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
                </p>
                <ol className={Styles.privacy__number_list}>
                  <li>
                    お客様又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                  </li>
                  <li>
                    当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                  </li>
                  <li>その他法令に違反することとなる場合</li>
                </ol>

                <h2 className={Styles.privacy__subtitle}>
                  第７条（個人情報の訂正及び削除）
                </h2>
                <ol>
                  <li>
                    当社の保有する個人情報が誤った情報である場合には、お客様の請求により、当社が定める手続きに従い個人情報の訂正又は削除を行います。
                  </li>
                  <li>
                    当社は、お客様から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正又は削除を行い、これをお客様に通知します。
                  </li>
                </ol>

                <h2 className={Styles.privacy__subtitle}>
                  第８条（個人情報の利用停止等）
                </h2>
                <p>
                  当社は、お客様から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、又は不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨お客様に通知します。但し、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、お客様の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
                </p>

                <h2 className={Styles.privacy__subtitle}>
                  第９条（プライバシーポリシーの変更手続）
                </h2>
                <p>
                  当社は本ポリシーの内容を適宜見直し、その改善に努めます。本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。変更後のプライバシーポリシーは、当社所定の方法により、お客様に通知し、又は当社ウェブサイトに掲載したときから効力を生じるものとします。
                </p>

                <h2 className={Styles.privacy__subtitle}>
                  第１０条（法令、規範の遵守）
                </h2>
                <p>
                  当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守します。
                </p>

                <h2 className={Styles.privacy__subtitle}>
                  第１１条（苦情及び相談への対応）
                </h2>
                <p>
                  当社は、個人情報の取扱いに関するお客様からの苦情、相談を受け付け、適切かつ迅速に対応いたします。また、お客様からの当該個人情報の開示、訂正、追加、削除、利用又は提供の拒否などのご要望に対しても、迅速かつ適切に対応いたします。
                </p>

                <h2 className={Styles.privacy__subtitle}>
                  第１２条（お問い合わせ窓口）
                </h2>
                <p>
                  当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
                </p>

                <div className={Styles.privacy__address}>
                  Uniforce株式会社　お客様対応窓口
                  <br />
                  〒150-0001 東京都渋谷区神宮前5丁目52-2 青山オーバルビル8階
                  <br />
                  corporate@uniforce.co.jp
                </div>
              </div>
              <div className={Styles.privacy__name}>
                <div className={Styles.privacy__history}>
                  <h3>改定履歴</h3>
                  <p>
                    ２０２１年１１月１２日制定・施行
                    <br />
                    ２０２２年１０月５日改訂
                    <br />
                    ２０２２年１１月４日改訂
                  </p>
                </div>
                <div className={Styles.privacy__namebox}>
                  Uniforce株式会社
                  <br />
                  代表取締役　砂田 和也
                </div>
              </div>
            </div>

            <div className={Styles.button__wrapper}>
              <div className={Styles.privacy__btn}>
                <BackButton path="/" />
              </div>
            </div>
          </Inner>
        </CompliancePageWrapper>
      </Layout>
    </>
  );
}
